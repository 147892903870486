@import './partials/vars';

@font-face {
    src: url('../fonts/Montserrat-Regular.ttf');
    font-family: 'Montserrat';
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
}

// independent element styles
#progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 3px;
    width: 0%;
    background-color: $main-color;
    z-index: 101;
    transition: .5s all;
}

#gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(51, 51, 51, 0.9);
    display: none;

    #close {
        display: inline-block;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        i {
            font-size: 3em;
            color: #fff;
            transition: .5s all ease;

            &:hover {
                transform: rotate(90deg);
            }
        }
    }

    #modal-wrapper {
        position: absolute;
        width: 70%;
        height: 70%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        img {
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            object-fit: cover;
        }

        span {
            position: absolute;
            top: 50%;
            cursor: pointer;

            i {
                transition: .4s all ease;
                font-size: 2.5em;
                color: #fff;
            }
        }

        span#left-arrow {
            left: -15%;
            transform: translateY(-50%);

            &:hover {
                i {
                    color: $main-color;
                }
            }
        }

        span#right-arrow {
            right: -15%;
            transform: translateY(-50%);

            &:hover {
                i {
                    color: $main-color;
                }
            }
        }

        span#counter {
            top: 102%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            font-size: 1.2em;
        }
    }

    &.activeModal {
        display: block;
    }
}

.main-info {
    text-transform: uppercase;

}

.mt-10 {
    margin: 10px 0;
    font-size: 1.2em;
}

.mt-30 {
    margin: 30px 0;
}

.about-us {
    font-size: 1em;
    font-weight: bold;
    text-transform: uppercase;
}


.button {
    display: inline-block;
    margin: 1em;
    padding: 1em 2em;
    text-decoration: none;
    text-transform: uppercase;
    border-radius: 20px;
    transition: 0.3s;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border: none;
    font-size: .9em;
    font-weight: normal;
    text-align: center;
    background-color: $main-color;
    cursor: pointer;
    transition: all .5s;
    width: 180px;
    color: white;
    outline: none;

    &:hover {
        width: 200px;
    }
}

#toaster {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #323232;
    padding: 12px 24px 17px 24px;
    vertical-align: middle;
    color: #fff;
    box-shadow: 0 7px 18px rgba(0, 0, 0, 0.2);
    border-radius: 1px;
    z-index: 10;
    display: none;
}

// "visible" styling
#sidebar-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    display: flex;
    text-transform: uppercase;
    font-size: .9em;
    font-weight: bold;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 15px 45px -9px rgba(0, 0, 0, 0.20);
    transition: .3s all;
    z-index: 1;

    &.toggle-menu {
        transform: translateX(-100%);
    }

    ul {
        display: flex;
        list-style: none;
        flex-direction: column;

        li {
            padding: 23px;

            &:last-of-type {
                cursor: pointer;
                transition: .5s all;

                &:hover {
                    color: $main-color;
                }
            }

            a {
                text-decoration: none;
                color: #535353;
                transition: color .2s;

                &:hover {
                    color: $main-color;
                }

                &.active-link {
                    color: #F1C81E;
                }
            }
        }
    }
}

#intro-container {
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    z-index: 1;
    overflow: hidden;
    transition: all .4s;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.2);
        z-index: -1;
    }

    #tape {
        position: absolute;
        top: 18em;
        left: 0;
        width: 100vw;
        height: 25em;
        background-color: $main-color;
        z-index: -1;
        transform: skewY(-210deg);
        opacity: .7;
        transition: .4s all;
    }

    header {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;

        a {
            flex-grow: 1;

            img {
                width: 100px;
                height: 100px;
            }
        }

        ul {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-grow: .4;
            list-style: none;

            li {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                position: relative;
                color: $text-color;

                ::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1px;
                    height: 100%;
                    background-color: $main-color;
                }

                i.main-color-icon {
                    color: $main-color;
                    font-size: 50px;
                }

                >div {
                    margin-left: 20px;
                    font-size: .8em;
                    text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);
                }
            }
        }
    }

    nav#navbar {
        width: 80%;
        margin: 15px auto 0 auto;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        font-size: .9em;
        font-weight: bold;
        background-color: #fff;
        border-radius: 3px;
        box-shadow: 0 15px 45px -9px rgba(0, 0, 0, 0.20);
        text-align: center;

        &.fixed {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            width: 80%;
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        ul {
            display: flex;
            list-style: none;
            width: 100%;

            li {
                padding: 23px;

                a {
                    text-decoration: none;
                    color: #535353;
                    transition: color .2s;

                    &:hover {
                        color: $main-color;
                    }

                    &.active-link {
                        color: #F1C81E;
                    }
                }

            }
        }

        div {
            display: none;
            flex: 1 1 50px;
            position: relative;
            top: 7px;

            input[type=checkbox] {
                cursor: pointer;
                position: absolute;
                top: 30%;
                left: 0;
                height: 25px;
                width: 27px;
                display: block;
                z-index: 2;
                opacity: 0;

                &:checked {
                    ~span {
                        &:first-of-type {
                            transform: rotate(45deg) translate(6px, 4px);
                        }

                        &:nth-of-type(2) {
                            opacity: 0;
                        }

                        &:last-of-type {
                            transform: rotate(-45deg) translate(4px, -2px);
                        }
                    }
                }
            }

            span {
                position: absolute;
                top: 50%;
                left: 0;
                width: 60%;
                height: 2px;
                background-color: black;
                transition: .4s all;

                &:first-of-type {
                    top: 24px;
                }

                &:nth-of-type(2) {
                    top: 30px;
                }

                &:last-of-type {
                    top: 36px;
                }
            }
        }
    }

    .intro-content {
        margin-top: 6.5em;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        color: $text-color;
        text-shadow: 1px 1px 2px rgba(150, 150, 150, 1);

        h1 {
            font-size: 3em;
        }

        p {
            width: 80%;
            margin: 0 auto;
            line-height: 25px;
            font-size: 1.2em;

            &:last-of-type {
                font-size: .8em;
            }
        }

        #balls {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 8em;

            >span {
                display: inline-block;
                width: 21px;
                height: 21px;
                box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.2);
                background-color: #fff;
                border-radius: 50%;
                margin: 0 5px;
                cursor: pointer;
                transition: all .4s;

                &.active {
                    width: 25px;
                    height: 25px;
                    background-color: $main-color;
                }
            }
        }

    }
}

#product-section {
    padding-top: 5em;
    width: 90%;
    margin: 0 auto;

    #products-wrapper {
        display: flex;

        >div {
            flex: 1;

            &:first-child {
                flex: .3;
            }
        }

        .products-description {
            margin-top: 20px;
            font-size: .85em;
            line-height: 20px;
            text-align: justify;
        }

        .products-grid {
            margin-left: 50px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 2em;
            text-align: center;

            >div {
                border-right: 1px solid $main-color;
                transition: .2s all ease-in-out;

                &:nth-child(3n + 1) {
                    border-left: 1px solid $main-color;
                }

                &:hover {
                    color: $main-color;
                }

                img {
                    width: 70px;
                    height: 70px;

                    &+p {
                        margin-top: 5px;
                        font-size: .9em;
                    }
                }
            }
        }
    }

    #products-gallery-wrapper {
        margin-top: 5em;
        text-align: center;

        #products-gallery {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 5px;
            margin-top: 2em;

            >div {
                cursor: pointer;
                width: 100%;
                height: 100%;
                opacity: .6;
                transition: .5s all ease-in-out;

                &:hover {
                    opacity: 1;
                }

                // grid children gallery
                &:last-child {
                    grid-row: 3 / 4;
                    grid-column: 3 / 5;
                    height: 300px;
                }

                &:nth-child(5) {
                    grid-row: 1 / 3;
                    grid-column: 2 / 3;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


#contact-wrapper {
    padding: 30px;
    width: 90%;
    margin: 7em auto 0 auto;
    background-color: #fff;
    box-shadow: 0 15px 45px -9px rgba(0, 0, 0, 0.20);
    display: flex;

    #message-section {
        flex: 60%;

        p {
            margin: 10px 0;
            text-transform: uppercase;
            font-weight: bold;
            font-size: .85em;

            &#validation {
                color: #f44336;
            }
        }

        input {
            padding: 15px 0;
            border: 1px solid #e3e3e3;
            margin-top: 7px;
            width: 100%;
            font-size: 1em;
            text-indent: 20px;
        }

        textarea {
            width: 100%;
            border: 1px solid #e3e3e3;
            resize: none;
            padding: 15px 3px;
            text-indent: 20px;
            font-size: 1em;
        }
    }

    #contacts {
        flex: 40%;
        text-align: center;
        padding: 0 4em;

        >div {
            margin-top: 2em;
            display: flex;
            align-items: center;
            text-align: left;

            i {
                font-size: 2em;
                color: #393939;
                display: inline-block;
                padding-right: 10px;
            }
        }
    }
}

section#about-us {
    position: relative;
    top: -50px;
    background: rgba(51, 52, 54, 0.8);
    padding: 7em 12em;
    box-shadow: 0 15px 45px -9px rgba(0, 0, 0, 0.20);
    z-index: -1;
    background: url('../img/construction.jpg') center center fixed no-repeat;
    background-size: cover;

    #about-us-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;

        >div {
            padding: 0 2em;
            grid-row: 1;

            &:nth-of-type(2) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                word-break: break-all;

                >div {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    i {
                        font-size: 2em;
                        color: #fff;
                        display: inline-block;
                        padding-right: 20px;
                    }
                }
            }

            h3 {
                font-size: 1.4em;
                font-weight: bold;
                text-transform: uppercase;
                color: #fff;
                padding-bottom: 5px;
            }

            .justify-desc {
                text-align: justify;
                text-justify: inter-word;
            }

            p {
                color: #fff;
                text-align: left;
                line-height: 25px;
                font-size: .9em;

                &.about-us {
                    text-align: left;
                }
            }

            ul {
                color: #fff;
                font-size: .9em;
                line-height: 25px;
            }
        }
    }
}

footer {
    text-align: center;
    padding-bottom: 3em;
}

// media-queires
@media(max-width: 1366px) {
    #intro-container {
        header {
            ul {
                flex-grow: 1;
            }
        }
    }
}

@media(max-width: 1280px) {
    section#about-us {
        padding: 7em 1em;

        #about-us-wrapper {
            grid-template-rows: auto 1fr;

            >div {
                text-align: center;
                padding-top: 10px;

                &:first-of-type {
                    grid-column: 1 / 4;
                    grid-row: 1 / 2;
                    padding-bottom: 2em;
                }

                &:nth-of-type(2) {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }

                &:last-of-type {
                    grid-column: 2 / 3;
                    grid-row: 2 / 3;
                }

                ul {
                    list-style: none;
                }
            }
        }
    }

}

@media(max-width: 1024px) {
    #intro-container {
        header {
            flex-direction: column;

            ul {
                width: 100%;
            }
        }
    }
}

@media(max-width: 768px) {
    #intro-container {
        header {
            ul {
                flex-direction: column;

                li {
                    justify-content: flex-start;
                    width: 100%;
                }
            }
        }

        nav#navbar {
            ul {
                padding: 36px;

                li {
                    display: none;
                }
            }

            div {
                display: flex;
            }
        }

        .intro-content {
            #balls {
                margin-top: 2em;
            }
        }
    }

    #product-section {
        #products-wrapper {
            flex-direction: column;

            .products-grid {
                margin-left: 0px;
            }

            >div {

                &:first-child {
                    margin-bottom: 2em;
                }
            }

        }
    }

    #contact-wrapper {
        flex-direction: column;

        #contacts {
            border-top: 1px solid gray;
            padding: 20px 0 0 0;
            word-break: break-all;

            >div {
                flex-direction: column;
                align-items: flex-start;
            }
        }

    }

    section#about-us {
        padding: 7em 1em;

        #about-us-wrapper {
            grid-template-rows: auto 1fr auto;
            justify-items: start;

            >div {
                text-align: left;
                padding-top: 10px;

                &:first-of-type {
                    grid-column: 1 / 4;
                    grid-row: 1 / 2;
                    padding-bottom: 2em;
                }

                &:nth-of-type(2) {
                    grid-column: 1 / 4;
                    grid-row: 2 / 3;

                    >div {
                        padding: .7em 0;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }

                &:last-of-type {
                    grid-column: 1 / 4;
                    grid-row: 3 / 4;
                    padding-top: 30px;
                }
            }
        }
    }

}

// sidenav bug fix
@media(min-width: 769px) {
    #sidebar-menu {
        display: none;
    }
}

@media(max-width: 640px) {
    #product-section {
        #products-gallery-wrapper {
            #products-gallery {
                grid-template-columns: 1fr 1fr;

                >div {
                    height: 200px;

                    &:nth-child(5) {
                        grid-row: 3 / 4;
                        grid-column: 1 / 2;
                    }

                    &:last-child {
                        grid-row: 5 / 6;
                        grid-column: 2 / 3;
                        height: 200px;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) {
    #intro-container {
        header {
            ul {
                li {
                    justify-content: flex-start;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }

        .intro-content {
            font-size: 12px;
            margin-top: 10px;

            #balls {
                margin-top: .7em;
            }
        }

    }

    #product-section {
        #products-wrapper {
            .products-grid {
                grid-template-columns: 1fr 1fr;

                >div {
                    border-left: 1px solid $main-color;
                    border-right: 1px solid $main-color;
                }
            }
        }

        #products-gallery-wrapper {
            #products-gallery {
                grid-template-columns: 1fr;

                >div {
                    height: 200px;

                    &:nth-child(5) {
                        grid-row: 5 / 6;
                        grid-column: 1 / 2;
                        height: 200px;
                    }

                    &:last-child {
                        grid-row: 11 / 12;
                        grid-column: 1 / 2;
                        height: 200px;
                    }
                }
            }
        }
    }

    #contact-wrapper {
        border-top: 1px solid gray;
        padding-top: 20px;

        #message-section {
            div {
                flex-direction: column;
            }
        }
    }

    section#about-us {
        padding: 5em 0;
    }
}

@media (max-width: 320px) {
    #product-section {
        #products-wrapper {
            .products-grid {
                grid-template-columns: 1fr;
            }
        }
    }
}